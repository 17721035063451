import client from '../helpers/authenticated-axios'

export const logService = {
  getLogs(page, itemsPerPage, filter, messageFilter, flowExecutionFilter, sort, callback) {
    let filters = '';
    if (messageFilter) filters += '&message=' + messageFilter;
    if (filter?.length > 0) filters += '&level_name[]=' + filter?.join('&level_name[]=');
    if (flowExecutionFilter) filters += '&flow_execution_id=' + flowExecutionFilter;
    client().get('/log_entries?page=' + page + '&itemsPerPage=' + itemsPerPage + filters + '&order[datetime]=' + sort).then((response) => {
      callback(response.data);
    }).catch((error) => {
      callback(() => error);
    });
  },

  downloadLogs(type, date, callback) {
    // Leave for debugging purposes
    if(type === "file") {
      client().get('/log/list').then((res) => {
        const files = res.data['hydra:member'].map(member => member.basename);
        this.extractArchive(type, date, files, callback);
      }).catch((error) => {
        callback(() => error);
      });
    } else {
      this.extractArchive(type, date, null, callback);
    }
  },

  extractArchive(type, date, files, callback) {
    const from = typeof date === "object" ? new Date(date.from).toISOString() : new Date(date).toISOString();
    let to = typeof date === "object" ? new Date(date.to).toISOString() : null;
    if(to !== null) { // Need to add a whole day, since the date picker passes 0:00:00, but we want the TO day to go to 23:59:59
      const nextDay = new Date(to);
      nextDay.setDate(nextDay.getDate() + 1);
      to = nextDay.toISOString();
    }

    const request = type === "file" ? "files" : "date";
    const body = type === "file" ?
      {"files": files} :
      {"from": from, "contentFilter": {"from": from, ...(to !== null && { to: to })}};
    client().post('/log/download/' + request, body, {
      responseType: 'blob' // Important to receive binary data
    }).then((response) => {
      const content = response.data;

      // Create object
      const blob = new Blob([content], { type: 'application/zip' });
      const url = window.URL.createObjectURL(blob);

      // Trigger object dl + clean up afterwards
      const link = document.createElement('a');
      link.href = url;
      const name = typeof from === 'undefined' ? new Date().toISOString() : from;
      link.setAttribute('download', name + '.zip');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);

      callback(response.data);

    }).catch((error) => {
      callback(() => error);
    });
  }
}
